
import { Col,Row, Collapse, Input, Form, Alert, Modal } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {UnorderedListOutlined, AppstoreOutlined} from '@ant-design/icons'

import Footer from "../../component/common/Footer";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import { Loader } from "react-feather";
import NodeItem from "./node-item";
import queryString from 'query-string';
import HeaderBuyNode from "../header-buy-node";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";

const GetRef = () => {
    const [form] = Form.useForm();
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState();
    const nodeDetailSection:any = useRef(null);

    const [refValue, setRefValue] = useState<any>('');
    const [loadingVerify , setLoadingVerify] = useState<any>(false);
    const [isSuccess , setIsSuccess] = useState<any>(false);
   
    const [refferal, setRefferal] = useState<any>(null);
    
    let refCode:any = '';
    let paramUrl:any = queryString.parse(window.location.search);
    if (paramUrl.ref !== undefined && paramUrl.ref.length > 0) {
        refCode = paramUrl.ref;
    }

    let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : '';

    const getRefCode = async ()=>{
        let resRef = await BaseApi.getRefCode(address);
        if(resRef.status == 200 && resRef.data.data){
            let data:any = resRef.data.data;
        
            setRefferal(data);
        }
    }

    
    useEffect(() => {
        if(address){
            getRefCode();
        }
        
    }, [address]);

    const onFill = () => {
        form.setFieldsValue({ 
            retweet_link: refferal?.retweet_link, 
            discord_link: refferal?.discord_link,
            telegram_link: refferal?.telegram_link,
            email: refferal?.email,
        });
    };

    useEffect(() => {
        if(refferal){
            onFill();
        }
        
    }, [refferal]);

    const onFinish = async (values:any) => {

        let data:any = {
            "wallet": address,
            "message": "Welcome to HeLa Node!",
            "signature": signatureMeta ? signatureMeta : '',
            "retweetLink": values?.retweet_link,
            "discordLink": values?.discord_link,
            "telegramLink": values?.telegram_link ? values?.telegram_link : '',
            "email": values?.email ? values?.email : '',
        }

        await BaseApi.submitRequestRefCode(data).then((res:any)=>{
            if(res.status == 201){
                
                form.resetFields();
                setIsSuccess(true);
            }
            
        }).catch((error:any)=>{
            notiError('Failed!', error?.message);
        });
    };

    return (
        <>
            <div className="app-page app-page-node">
                <HeaderBuyNode />
                <div className="container">
                    <div className="nodes-sale pt-0">
                        <Row gutter={30}>
                            <Col xs={24}>
                                <p className="text-darkblue-500 font-18 mt-0">Get your Referral Code</p>
                                <div className="node-banner">
                                    <div className="max-ref">
                                        <div className="banner-info">
                                            <img src="../images/sale-banner.svg" />
                                        </div>
                                        <h3 className="text-darkblue-500 text-center weight-600 font-20 mt-0 mb-10">Become a Guardian</h3>
                                        <div className=" text-center weight-300 font-16">
                                            Join our elite network as a Guardian! Share your referral code with friends to unlock huge rewards.
                                        </div>
                                        <p className="text-center"><a className="text-darkblue-500 font-16 flex-center" href="https://x.com/Hela_Network" target="_blank"><span>HeLa on X</span>  <img src="../images/ico_link.svg" /></a></p>
                                        <p className="text-center"><a className="text-darkblue-500 font-16 flex-center" href="https://discord.com/invite/helalabs"  target="_blank"><span>HeLa Discord channel</span>  <img src="../images/ico_link.svg" /></a></p>
                                        <div className="">
                                            
                                            <Form
                                                form={form}
                                                name="basic"
                                                layout="vertical"
                                                onFinish={onFinish}
                                            >
                                                <Form.Item
                                                    className='ref-social-input mt-10'
                                                    label={<><label className="weight-500">Retweet X link</label></>}
                                                    name="retweet_link"
                                                    // initialValue={refferal?.retweet_link}
                                                    rules={[{ required: true, message: 'Enter link you had retweet!' }]}
                                                >
                                                    <Input
                                                        className='ref-social-input'
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter link you had retweet"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='ref-social-input mt-10'
                                                    label={<><label className="weight-500">Share Discord link</label></>}
                                                    name="discord_link"
                                                    // initialValue={refferal?.discord_link}
                                                    rules={[{ required: true, message: 'Enter link you had shared on discord' }]}
                                                >
                                                    <Input
                                                        className='ref-social-input'
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter link you had shared on discord"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='ref-social-input mt-10'
                                                    label={<><label className="weight-500">Telegram account</label></>}
                                                    name="telegram_link"
                                                    rules={[{ required: true, message: 'Enter telegram account' }]}
                                                >
                                                    <Input
                                                        className='ref-social-input'
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter Telegram account"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='ref-social-input mt-10'
                                                    label={<><label className="weight-500">Email</label></>}
                                                    name="email"
                                                    rules={[{ required: true, message: 'Enter email' }]}
                                                >
                                                    <Input
                                                        type="email"
                                                        className='ref-social-input'
                                                        style={{ width: '100%' }}
                                                        placeholder="Enter email"
                                                    />
                                                </Form.Item>
                                                <Form.Item className="text-center">
                                                    {address ? (
                                                        <>
                                                            <button type="submit" className="btn-blue-light text-white p-10-15">Get Your Referral Code</button>
                                                        </>
                                                    ):(
                                                        <>
                                                            <WagmiConnect />
                                                        </>
                                                    )}
                                                    
                                                </Form.Item>

                                                
                                            </Form>
                                        
                                        </div>
                                        
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Modal footer={false} onCancel={()=> window.location.reload()} onOk={()=> window.location.reload()} className="modal-mint modal-salenode" title={<></>} open={isSuccess}>
                            <div className="text-center text-blue font-24 mt-0 border-bottom-10 mb-15">Successfully!</div>
                            
                            <div className="text-center mb-15">
                                <p className="text-green font-18">We received your application, please wait for approval</p>
                                <div className="">
                                    <img src="../images/check.svg"/>
                                </div>
                                
                            </div>
                        </Modal>

                        {/* <div className='mt-15'>
                            <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition="end" className='collapse-cus'>
                                <Panel header={<><span className='mt-5 mb-0 text-medium-blue font-16 text-weight-500'>How does the launchpad work?</span></>} key="1">
                                    <p className='mt-5 mb-0'>This launchpad operates on the OVERFLOW method.</p>
                                    <p className='mt-5 mb-0'>In the overflow method, users can subscribe as much as they want to the launchpad, and their final allocation will be based on the amount of funds they put in as a percentage of all funds put in by other users at the time the sale ends.</p>
                                    <p className='mt-5 mb-0'>Users will get back any leftover funds when they claim their nodes after the sale.</p>
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-medium-blue font-16 text-weight-500'>How to buy HeLa Guardian Node on Upswap?</span></>} key="2">
                                    <p className='mt-5 mb-0'>Secure your spot in HeLa Node Sale by following these steps 👆</p>
                                    <p className='mt-5 mb-0'>🗝 Important: </p>
                                    <p className='mt-5 mb-0'>✅ Ensure you have HLUSD on HeLa to cover gas fees</p>
                                    <p className='mt-5 mb-0'>✅ You can commit to buy as many nodes as you want</p>
                                    <p className='mt-5 mb-0'>👉 Step-by-step guide: <a target="_blank" href='https://medium.com/upswap-finance/how-to-buy-hela-node-sale-on-upswap-24acc41caf2e'>https://medium.com/upswap-finance/how-to-buy-hela-node-sale-on-upswap-24acc41caf2e</a> </p>
                                    <p className='mt-5 mb-0'>👉 For mobile users, use the metamask mobile browser: <a target="_blank" href='https://support.metamask.io/vi/getting-started/how-to-use-the-metamask-mobile-browser'>https://support.metamask.io/vi/getting-started/how-to-use-the-metamask-mobile-browser</a> </p>
                                    <p className='mt-5 mb-0'>👉 Watch video guide: <a target="_blank" href='https://www.youtube.com/watch?v=72yCxjBqdMc'>https://www.youtube.com/watch?v=72yCxjBqdMc</a> </p>
                                </Panel>
                                <Panel header={<><span className='mt-5 mb-0 text-medium-blue font-16 text-weight-500'>Benefits of buying HeLa Guardian Node</span></>} key="3">
                                    <p className='mt-5 mb-0'>1) Instant airdrop of 288 HeLa tokens upon TGE</p>
                                    <p className='mt-5 mb-0'>2) Linear vesting of 864 HeLa tokens over 4 years</p>
                                    <p className='mt-5 mb-0'>3) Priority airdrop and whitelist of protocols built atop HeLa!</p>
                                    <p className='mt-5 mb-0'><a target="_blank" href='https://docs.helalabs.com/hela-node/hela-guardian-node/node-rewards'>Click here</a> to find out more about HeLa Guardian Node Incentives!</p>
                                </Panel>
                            </Collapse>
                        </div> */}

                    </div>

                    
                </div>
                
            </div>
            <Footer />
            {loadingVerify && (
                <>
                    <Loader />
                </>
            )}
        </>
    );
};
export default GetRef;
