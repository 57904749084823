
import { Col, message, Input, Layout, Row, Progress, Spin, Tag, Empty, Checkbox, Modal, Alert, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";
import type { CheckboxProps } from 'antd';
import {CheckOutlined, CloseCircleOutlined, DoubleLeftOutlined} from '@ant-design/icons'
import { AbiItem } from "web3-utils";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import HelaNodeSaleAbi from '../../config/abi/HelaNodeSaleAbi.json'

import Web3 from "web3";
import networkChange from "../../utils/networkChange";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";
import CountdownComponent from "../../utils/countDown";
import CountdownReloadComponent from "../../utils/countDownReload";
import { convertToWei } from "../../utils/convertNumber";
import { convertDateTime } from "../../utils/formatDate";
import { NodeTier } from "../../constants";
import CountdownBlue from "../../utils/countDownBlue";

declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
};

const NodeItem = (props:any) => {

    const { userRazer, nodeActive, refCode } = props;
    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState();
    const chainId: any = selectedNetworkId
    const [mintQuantity, setMintQuantity] = useState<any>(1);
    
    const [totalPay , setTotalPay] = useState(0);
    const [loadingApply , setLoadingApply] = useState<any>(false);
    const [loadingBuy , setLoadingBuy] = useState<any>(false);
    const [ethBalance, setEthBalance] = useState<any>(0);
    const [totalNodeBuyed, setTotalNodeBuyed] = useState<any>(0);
    const [refInfo, setRefInfo] = useState<any>(null);
    const [refValue, setRefValue] = useState<any>('');
    const [nodePrice, setNodePrice] = useState<any>(0);
    const [loadingWl , setLoadingWl] = useState<any>(false);
    const [isWhitelist , setIsWhitelist] = useState<any>(false);
    const [messageError , setMessageError] = useState<any>('');
    const [messageSuccess , setMessageSuccess] = useState<any>('');
    const [setting, setSetting] = useState<any>(null);
    const [terms, setTerms] = useState<any>(false);
    const [buyTerms, setBuyTerms] = useState<any>(false);
    const [policy, setPolicy] = useState<any>(false);
    const [investments, setInvestments] = useState<any>(false);
    const [isConfirmBuy, setIsConfirmBuy] = useState<any>(false);
    const [isSuccess, setIsSuccess] = useState<any>(false);
    const [hashSuccess, setHashSuccess] = useState<any>('');
    const [orderDetails , setOrderDetails] = useState([]);
    const [orderTotal , setOrderTotal] = useState(0);
    const [isMailChip , setIsMailChip] = useState(false);

    const [mailEmail, setMailEmail] = useState('');
    const [mailFname, setMailFname] = useState('');
    const [mailNationality, setMailNationality] = useState('');
    const [mailTelegramID, setMailTelegramID] = useState('');
    const [mailTwitterID, setMailTwitterID] = useState('');
    const [mailDiscordID, setMailDiscordID] = useState('');

    let web3:any;
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
    

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : '';

    useEffect(() => {
        if (nodeActive) {
            if(isWhitelist == true){
                setNodePrice(nodeActive?.whitelist_price);
            }else{
                setNodePrice(nodeActive?.price);
            }
            
        }
    }, [nodeActive, isWhitelist])

    
    

    const getEthBalance = async ()=>{
        if(address){
            let balanMainToken = await web3.eth.getBalance(address);
            
            setEthBalance(Number(balanMainToken) / 1e18);

        }
    }

    useEffect(() => {
        if(address){
            getEthBalance();
        }
    }, [address]);

    useEffect(() => {
        if (mintQuantity && nodePrice) {
            setTotalPay(nodePrice * mintQuantity)
        }
    }, [mintQuantity, nodePrice]);

    

    const getAmountTierByAccount = async() => {
        try {
            const response = await BaseApi.getAmountTierByAccount(nodeActive?.tier, address);
            
            if(response.status == 200 && response.data && response.data?.data?.total_node){
               setTotalNodeBuyed(response.data?.data?.total_node);
            }else{
                setTotalNodeBuyed(0);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const getSetting = async() => {
        try {
            const response = await BaseApi.getSetting();
            
            if(response.status == 200 && response.data && response.data?.data){
               setSetting(response.data?.data);
            }else{
                setSetting(null);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const checkUserHadWl = async() => {
        setLoadingWl(true);
        try {
            const response = await BaseApi.checkWlTierAddress(nodeActive?.tier, address);
      
            if(response.status == 201 && response.data){
                setMintQuantity(1);
               setIsWhitelist(response.data?.data);
               setLoadingWl(false);
            }else{
                setIsWhitelist(false);
                setLoadingWl(false);
            }
        } catch (error) {
            setIsWhitelist(false);
            setLoadingWl(false);
        }
    };

    useEffect(() => {
        getSetting();
        if(address && nodeActive){
            getAmountTierByAccount();
            checkUserHadWl();
        }
    }, [address ,nodeActive]);

    useEffect(() => {
        let ref = '';
        if(refCode != ''){
            ref = refCode;
            setRefValue(ref);
        }
        
    }, [refCode]);

    const onPlaceOrder = ()=>{
        setIsConfirmBuy(true);
    }

    const onBuyNode = async ()=>{
        setLoadingBuy(true);

        try {
            
            if (totalPay === 0) {
                notiError('Amount must be greater than zero', 4)
                setLoadingBuy(false)
                return
            }
            if (signatureMeta === '') {
                notiError('Sign invalid', 4)
                setLoadingBuy(false)
                return
            }
            if (mintQuantity <= 0) {
                notiError('Amount node buy invalid', 4)
                setLoadingBuy(false)
                return
            }

            let data:any = {
                "wallet": address,
                "message": "Welcome to HeLa Node!",
                "signature": signatureMeta,
                "tier_id": nodeActive?.tier,
                "chainId": chainId,
                "totalNode": mintQuantity,
                "refCode": refValue ? refValue : ''
            }

            let orderRes = await BaseApi.createOrderNode(data);
            if(orderRes && orderRes.status == 201 && orderRes.data.data){

                let orders:any = orderRes.data?.data?.order_details;
                setOrderDetails(orders);
                setOrderTotal(orderRes.data?.data?.total);
                let arrData:any = [];
                let contractAddress:any = '';
                if(orders.length > 0){
                    for (let i = 0; i < orders.length; i++) {
                        // if(i == 0){
                            let order:any = orders[i];
                            let item:any = [
                                order?.id,
                                order?.tier,
                                order?.unit_price,
                                order?.total_node,
                                order?.total_paid,
                                order?.signature
                            ];
                            arrData.push(item);
                        // }
                        
                    }
                    contractAddress = orders[0]?.contract_address;
                }
                


                if(arrData.length > 0){
                    let buyNodeContract:any = new web3.eth.Contract(
                        HelaNodeSaleAbi as unknown as AbiItem,
                        contractAddress
                    );

                    let valueStakeConverted = convertToWei(orderRes.data?.data?.total, 18);
                    console.log('arrData', arrData);
                    const gasPrice = await web3.eth.getGasPrice();

                    let gasEstimate = await buyNodeContract.methods
                        .buyNode(arrData)
                        .estimateGas({
                            from: address,
                            value: valueStakeConverted,
                            gasPrice: web3.utils.toHex(String(gasPrice)),
                        });

                    await buyNodeContract.methods.buyNode(arrData)
                    .send({
                        from: address,
                        value: valueStakeConverted,
                        gas: gasEstimate,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    })
                    .then(async (resStake: any) => {
                        
                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                resStake?.transactionHash,
                                function (err: any, rec: any) {
                                    if (rec) {
                                        
                                        clearInterval(interval);
                                        notiSuccess('Successfully!', 5);
                                        setHashSuccess(resStake?.transactionHash);
                                        setLoadingBuy(false);
                                        setIsSuccess(true);

                                        // setTimeout(() => {
                                        //     setLoadingBuy(false);
                                        //     window.location.reload();
                                        // }, 2500);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        setLoadingBuy(false)
                                        notiError('Error Staking', 3);
                                        setOrderDetails([]);
                                        setOrderTotal(0);
                                    }
                                }
                            )
                        }, 1000);
                    })
                    .catch((errStake: any) => {
                        console.log('errStake', errStake)
                        notiError(errStake?.message || errStake, 3)
                        setLoadingBuy(false);
                        setOrderDetails([]);
                        setOrderTotal(0);
                    })
                }
            }
    
        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingBuy(false);
            setOrderDetails([]);
            setOrderTotal(0);
        }
    }

    const handleApplyDisccount =async (refCode:any) => {
        try {
            setLoadingApply(true);
            let data:any = {
                "ref_code": refCode,
                "wallet": address
            }
            let rs = await BaseApi.checkRefCode(data);
            
            if(rs.status == 201 && rs.data?.data && rs?.data?.data?.code){
                setRefInfo(rs.data.data);
                setMessageSuccess('Valid');
                setLoadingApply(false);
            }else{
         
                setMessageError('Invalid');
                setRefInfo(null);
                setRefValue('');
                setLoadingApply(false);
            }
        } catch (error:any) {
            
            let mess = 'Ref code not found';
            if(error?.message == 'BAD_REQUEST'){
                mess = 'Self-ref code is not allowed';
            }
            setMessageError(mess);
            setRefInfo(null);
            setRefValue('');
            setLoadingApply(false);
        }
    }

    const handleIncreaseQuantity = () => {
        let quantity = mintQuantity + 1;

        let availableLimit:any = setting?.daily_limit - setting?.sold_nodes;

        if(quantity > availableLimit){
            setMintQuantity(availableLimit);
            setTotalPay(nodePrice * availableLimit);
        }else{
            setMintQuantity(quantity);
            setTotalPay(nodePrice * quantity);  
        }
    };
    const handleDecreaseQuantity = () => {
        let quantity = mintQuantity - 1;
        setMintQuantity(quantity);
        setTotalPay(nodePrice * quantity);
    };

    const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue:any = e.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {

                let availableLimit:any = setting?.daily_limit - setting?.sold_nodes;
                if(Number(inputValue) > availableLimit){
                    setMintQuantity(Number(availableLimit));
                    setTotalPay(nodePrice * Number(availableLimit));
                }else{
                    setMintQuantity(Number(inputValue));
                    setTotalPay(nodePrice * Number(inputValue));
                }
            
        }
    };
    const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue:any = e.target.value;
        setRefValue(inputValue);
        setRefInfo(null);
        setMessageError('');
        setMessageSuccess('');
    };

    

    let nowDate = new Date();
    let startTime = setting?.start_time ? new Date(setting?.start_time) : '';
    let endTime = setting?.end_time ? new Date(setting?.end_time) : '';

    const changeTerms: CheckboxProps['onChange'] = (e) => {
        let checked = e.target.checked;
        setTerms(checked);
    };
    const changeBuyTerms: CheckboxProps['onChange'] = (e) => {
        let checked = e.target.checked;
        setBuyTerms(checked);
    };

    const changePolicy: CheckboxProps['onChange'] = (e) => {
        let checked = e.target.checked;
        setPolicy(checked);
    };

    const changeInvestments: CheckboxProps['onChange'] = (e) => {
        let checked = e.target.checked;
        setInvestments(checked);
    };

    let tommorow:any = new Date(+new Date().setHours(0, 0, 0, 0) + 86400000);
    let tommorowUtc:any = convertDateTime(tommorow);

    const goBack = ()=>{
        setPolicy(false);
        setTerms(false);
        setBuyTerms(false);
        setIsConfirmBuy(false);
    }
    const showMailChip = ()=>{
        setIsMailChip(true);
    }

    const hideMailChip = ()=>{
        setIsMailChip(false);
        window.location.reload();
    }

    const columns = [
        
        {
            title: <div className='text-white text-center'>Tier</div>,
            dataIndex: 'node_tier_id',
            key: 'node_tier_id',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-black text-center'>{record?.node_tier_id}</div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Quantity</div>,
            dataIndex: 'total_node',
            key: 'total_node',
            render: (text:any, record:any) => {

				
				return (
					<div className='text-black text-center'>{ record?.total_node > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 0}).format(record?.total_node) : 0} </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Price</div>,
            dataIndex: 'price',
            key: 'price',
            render: (text:any, record:any) => {

				
				return (
					<div className='text-black text-center'>{ record?.price > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.price) : 0} </div>
				)
			}
        },
    ];
  
    return (
        <>
            {!isConfirmBuy ? (
                <>
                    {/* <div className="daily-limit mt-10">
                        Available Nodes to Purchase: <span className="weight-500 font-18">{setting?.daily_limit - setting?.sold_nodes}</span> Nodes
                    </div> */}
                    <div className="text-center">
                        <a className="text-decoration text-darkblue-500" onClick={showMailChip}>Contact us directly for exclusive access to node allocation. Limited availability—secure yours now!</a>
                    </div>
                    {startTime != '' && startTime > nowDate && (
                        <>
                            <div className="flex-btw mt-15">
                                <span className="weight-300 ">Starts in</span>
                                <span className=" weight-400">{CountdownBlue(setting?.start_time)}</span>
                            </div>
                        </>
                    )}
                    
                    {startTime == '' && (
                        <>
                            <Tag className="w-full p-15 font-18 text-center" color="#09A9CB">Coming Soon</Tag>
                        </>
                    )}
                    {/* {startTime != '' && startTime < nowDate && endTime > nowDate && (
                        <div className="flex-btw mt-15">
                            <span className="weight-300 ">End In</span>
                            <span className=" weight-400">{CountdownReloadComponent(setting?.end_time)}</span>
                        </div>
                    )} */}
                    
                    <div className="mt-5">
                        <p className="weight-300 font-20  mt-15 mb-10">Price</p>
                        <p className="weight-500  font-24 mt-0 mb-10">
                            {nodePrice > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(nodePrice) : 0} HLUSD
                        </p>
                    </div>
                    <div className="flex-btw mt-15 gap-15">
                        <span className="weight-400  font-18">Quantity</span>
                        <div className="weight-400  font-18">
                            <Input
                                className='grp-input'
                                style={{ width: '100%' }}
                                addonBefore={
                                    <button onClick={handleDecreaseQuantity} disabled={mintQuantity <= 1 || ethBalance == 0} className='btn-increase'>-</button>
                                } 
                                addonAfter={
                                    <button onClick={handleIncreaseQuantity} disabled={ethBalance == 0 || mintQuantity >= (setting?.daily_limit - setting?.sold_nodes)} className='btn-increase'>+</button>
                                }
                                onChange={handleChangeAmount}
                                readOnly={Number(nodeActive?.sold_node) >= Number(nodeActive?.total_node)}
                                value={mintQuantity} 
                            />
                        </div>
                    </div>
                    <div className="flex-btw mt-15 mb-15 gap-15">
                        <span className="weight-400 ">Your HLUSD</span>
                        <span className="weight-400 font-18 ">
                            {totalPay > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(ethBalance) : 0} <span className="text-graylight">HLUSD</span>
                        </span>
                    </div>
                    
                    <hr className=""/>

                    <Checkbox className="mt-5 check-tern font-14" onChange={changeTerms}>I have read, understand and agree to the <a className="text-darkblue-500 text-decoration" href="https://helalabs.com/nodesterms/" target="_blank">Terms of Service</a></Checkbox>
                    <Checkbox className="mt-10 check-tern font-14" onChange={changePolicy}>I have read, understand and agree to the <a className="text-darkblue-500 text-decoration" href="https://helalabs.com/privacy-policy" target="_blank">Privacy Policy</a></Checkbox>
                    <Checkbox className="mt-10 check-tern font-14" onChange={changeInvestments}>I have read, understand and agree that HeLa Guardian Node are not investments</Checkbox>
                    
                    <div className="text-center mt-15">
                    
                        <>
                                
                            {chainId == 8668 ? (
                                <>
                                    {startTime != '' && startTime < nowDate && (
                                        <>
                                            {/* <button className="btn-lightblue w-full border-black" disabled={loadingBuy || !address || ethBalance < totalPay || !nodeActive?.active} onClick={onBuyNode}>Buy {loadingBuy && <Spin size="small"/>}</button> */}
                                            <button className="btn-blue-light w-full p-12-15 font-18" disabled={ethBalance == 0 || !nodeActive?.active || (terms == false || policy == false || investments == false) || setting?.sold_nodes >= setting?.daily_limit || mintQuantity > (setting?.daily_limit - setting?.sold_nodes)} onClick={onPlaceOrder}>{ethBalance == 0 ? 'Insufficient balance' : 'Buy Now'}  {loadingBuy && <Spin size="small"/>}</button>
                                            {setting?.sold_nodes >= setting?.daily_limit && (
                                                <>
                                                    <Alert className="p-15 mt-10 text-danger" type="error" description={<>
                                                        <p>Today allocated sold out, please come back tomorrow.</p>
                                                        <div className="mt-10">
                                                            {CountdownBlue(tommorowUtc)}
                                                        </div>
                                                    </>}></Alert>
                                                    
                                                </>
                                            )}
                                            
                                        </>
                                    )}
                                    
                                </>
                            ):(
                                <>
                                    {chainId ? (
                                        <>
                                            <button className="mt-10 btn-lightblue font-16 border-black" onClick={()=> networkChange(8668)}>
                                                Switch to HeLa
                                            </button>
                                        </>
                                    ):(
                                        <>
                                            <WagmiConnect />
                                        </>
                                    )}
                                    
                                </>
                            )}
                            
                        </>
                    </div>
                </>
            ):(
                <>
                    {!isSuccess ? (
                        <>
                            <div className="flex-btw mb-20">
                                <span className="font-20 text-darkblue-500 weight-600">Order summary</span>
                                <a className="text-darkblue-500 font-16 text-decoration flex-center" onClick={goBack}><DoubleLeftOutlined /> Back</a>
                            </div>
                            {/* <div className="flex-btw mt-15 mb-15 gap-15">
                                <span className="weight-300  font-18">Price</span>
                                <span className="weight-400  font-20">{nodePrice > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(nodePrice) : 0} HLUSD</span>
                            </div> */}
                            {/* <hr /> */}
                            <div className="flex-btw mt-50 mb-15 gap-15">
                                <span className="weight-300  font-18">Quantity</span>
                                <span className="weight-400  font-20">{mintQuantity}</span>
                            </div>
                            {/* <hr /> */}
                            <div className="mt-15">
                                <Input
                                    className='ref-social-input'
                                    style={{ width: '100%' }}
                                    placeholder="Ref Code"
                                    addonAfter={
                                        <button disabled={refValue == '' || refInfo != null || isWhitelist} onClick={()=>handleApplyDisccount(refValue)} className='btn-lightblue'>{refInfo ? 'Applied' : 'Apply'} {loadingApply && <><Spin /></>}</button>
                                    }
                                    readOnly={isWhitelist}
                                    onChange={handleChangeCode}
                                    value={isWhitelist ? '' : refValue} 
                                />
                                {messageError && (<small><CloseCircleOutlined className="text-danger"/> <i className="text-danger">{messageError}</i></small>)}
                                {messageSuccess && (<small><CheckOutlined className="text-success"/> <i className="text-success">{messageSuccess}</i></small>)}
                            </div>
                            {/* {refInfo && (
                                <div className="flex-btw mt-15">
                                    <span className="weight-300 ">Discount (%)</span>
                                    <div className="weight-400  font-18">
                                        {refInfo?.rebate_percent * 100}
                                    </div>
                                </div>
                            )}
                            {refInfo && (
                                <div className="mt-15">
                                    <i className="weight-300  font-16">Rebate amount of <span className="weight-500">{refInfo?.rebate_percent && totalPay > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:10}).format(Number(refInfo?.rebate_percent) * totalPay) : 0}</span> is claimable after nodes are launched.</i>
                                </div>
                            )} */}
                            {/* <hr /> */}
                            <div className="flex-btw mt-15 mb-15 gap-15">
                                <span className="weight-400 ">Total</span>
                                <span className="weight-400 font-18 ">
                                    {orderTotal > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(orderTotal) : new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(totalPay)} <span className="text-graylight">HLUSD</span>
                                </span>
                            </div>
                            <div className="flex-btw mt-15 mb-15 gap-15">
                                <span className="weight-400 ">Your HLUSD</span>
                                <span className="weight-400 font-18 ">
                                    {totalPay > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(ethBalance) : 0} <span className="text-graylight">HLUSD</span>
                                </span>
                            </div>
                            <div className="text-center" style={{marginTop: '70px'}}>
                            
                                <>
                                        
                                    {chainId == 8668 ? (
                                        <>
                                            {startTime != '' && startTime < nowDate && (
                                                <>
                                                    <button className="btn-blue-light w-full font-18 p-12-15" disabled={loadingBuy || !address || ethBalance < totalPay || !nodeActive?.active || !buyTerms} onClick={onBuyNode}>{ loadingBuy ? 'Buying' : 'Place Order' } {loadingBuy && <Spin size="small"/>}</button>
                                                </>
                                            )}
                                            
                                        </>
                                    ):(
                                        <>
                                            {chainId ? (
                                                <>
                                                    <button className="mt-10 btn-lightblue font-16 border-black" onClick={()=> networkChange(8668)}>
                                                        Switch to HeLa
                                                    </button>
                                                </>
                                            ):(
                                                <>
                                                    <WagmiConnect />
                                                </>
                                            )}
                                            
                                        </>
                                    )}
                                    
                                </>
                                <Checkbox className="mt-30 check-tern" onChange={changeBuyTerms}>By continuing you have indicated that you have read and agree to our <a className="text-darkblue-500 text-decoration" href="https://helalabs.com/nodesterms/" target="_blank">Terms of Service</a></Checkbox>
                            </div>
                        </>
                    ):(
                        <>
                            <div className="font-20 text-darkblue-500 weight-600">Order summary</div>
                            
                            <div className="mt-10 mb-10">
                                <div className="">
                                    <Table className="table-buy" bordered={false} rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'} dataSource={orderDetails} columns={columns} pagination={false}/>
                                </div>
                                {/* {orderDetails.length > 0 && orderDetails.map((item:any, key:any) => {
                                    
                                    return(
                                        <>
                                            <div className="flex-btw mb-15" key={`key--${key}`}>
                                                <div className=""><span className="">Tier:</span> <span className="text-darkblue-500">{item?.node_tier_id}</span></div>
                                                <div className=""><span className="">Qty:</span> <span className="text-darkblue-500">{item?.total_node}</span></div>
                                                <div className=""><span className="">Price:</span> <span className="text-darkblue-500">{item?.price}</span></div>
                                            </div>
                                        </>
                                    )
                                })} */}

                            </div>
                            
                            <div className="flex-btw mt-15 mb-15 gap-15">
                                <span className="weight-300 ">Total</span>
                                <span className="weight-400 ">
                                    {orderTotal > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(orderTotal) : new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(totalPay)} <span className="text-graylight">HLUSD</span>
                                </span>
                            </div>
                            {/* <hr /> */}
                            <div className="flex-btw mt-15 mb-15 gap-15">
                                <span className="weight-300 ">TX Hash:</span>
                                <span className="weight-400 ">
                                    <a className="text-info text-decoration" href={`https://helascan.io/tx/${hashSuccess}`} target="_blank">{hashSuccess ? `${hashSuccess.substring(0, 4)}...${hashSuccess.substring(hashSuccess.length - 4)}` : "---"}</a>
                                </span>
                            </div>
                            <div className="flex-center text-center mt-15">
                                {/* <button className="btn-blue-light" onClick={()=> window.location.replace('/stake')}> Mint now</button> */}
                                <button className="btn-blue-light" onClick={()=> window.location.replace('/profile')}> My Nodes</button>
                            </div>
                        </>
                    )}
                    
                </>
            )}
            <Modal footer={false} className="modal-mint modal-salenode" title={<></>} open={loadingBuy}>
                <div className="text-center text-darkblue-500 font-24 mt-0 border-bottom-10 mb-15">Order Pending!</div>
                <p className="text-center text-gray-light">Please complete the transaction in your connected wallet before the timer runs out and stay on this page until the transaction is sent.</p>
                <div className="d-flex-mid gap-15 mb-15">
                    <div className="">
                        <img src="../images/node-icon.svg"/>
                    </div>
                    <div className="">
                        <div className="text-darkblue-500 font-24 mb-10">HeLa Guardian Node</div>

                        
                        {orderDetails.length > 0 && orderDetails.map((item:any, key:any) => {
                                    
                            return(
                                <>
                                    <div className="flex-btw mb-5" key={`keys--${key}`}>
                                        <div className=""><span className="text-white">Tier:</span> <span className="text-darkblue-500">{item?.node_tier_id}</span></div>
                                        <div className=""><span className="text-white">Qty:</span> <span className="text-darkblue-500">{item?.total_node}</span></div>
                                        <div className=""><span className="text-white">Price:</span> <span className="text-darkblue-500">{item?.price}</span></div>
                                    </div>
                                </>
                            )
                        })}
                        <div className="flex-btw mt-15 mb-15 gap-15">
                            <span className="weight-300 ">Total Pay</span>
                            <span className="weight-400 ">
                                {orderTotal > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(orderTotal) : new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(totalPay)} <span className="text-graylight">HLUSD</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="text-center mb-15">
                    <Spin size="large"/>
                </div>
            </Modal>

            <Modal footer={false} onCancel={hideMailChip} onOk={hideMailChip} className="modal-salenode m-auto" title={<></>} open={isMailChip}>
                <div id="mc_embed_shell">
                    <div id="mc_embed_signup" className="m-auto">
                        <form action="https://helalabs.us4.list-manage.com/subscribe/post?u=31d5a448baa1cc3aa28653255&amp;id=27ff63cbe4&amp;f_id=008a7ceaf0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                            <div id="mc_embed_signup_scroll">
                                <h2>Need More Nodes? Subscribe Us and We will Get in Touch with You Shortly!</h2>
                                <div className="indicates-required">
                                    <span className="asterisk">*</span> indicates required
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                                    <input type="email" onChange={(e) => setMailEmail(e.target.value)} name="EMAIL" className="required email" required value={mailEmail} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-FNAME">First Name </label>
                                    <input type="text" onChange={(e) => setMailFname(e.target.value)} name="FNAME" className=" text" id="mce-FNAME" value={mailFname} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-MMERGE3">Nationality </label>
                                    <input type="text" onChange={(e) => setMailNationality(e.target.value)} name="MMERGE3" className=" text" id="mce-MMERGE3" value={mailNationality} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-MMERGE4">Telegram ID <span className="asterisk">*</span></label>
                                    <input type="text" onChange={(e) => setMailTelegramID(e.target.value)} name="MMERGE4" className="required text" id="mce-MMERGE4" required value={mailTelegramID} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-MMERGE5">Twitter ID </label>
                                    <input type="text" onChange={(e) => setMailTwitterID(e.target.value)} name="MMERGE5" className=" text" id="mce-MMERGE5" value={mailTwitterID} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-MMERGE6">Discord ID </label>
                                    <input type="text" onChange={(e) => setMailDiscordID(e.target.value)} name="MMERGE6" className=" text" id="mce-MMERGE6" value={mailDiscordID} />
                                </div>
                                <div id="mce-responses" className="clear">
                                    <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                                    <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                                </div>
                                <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
                                    <input type="text" name="b_31d5a448baa1cc3aa28653255_27ff63cbe4" value="" />
                                </div>
                                <div className="clear">
                                    <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default NodeItem;
